// 1. Import the extendTheme util - it will merge with the default theme.
import { extendTheme } from '@chakra-ui/react';

const config = {
  useSystemColorMode: false,
  initialColorMode: 'light',
};

// 2. Extend the theme to include custom colors, fonts, etc.
const colors = {
  backgroundColor: '#FFFFFF',
  lime: {
    100: '#E0FFF6',
    200: '#C0FFED',
    300: '#A1FFE4',
    400: '#81FFDB',
    500: '#62FFD2',
    600: '#43FFC9',
    700: '#23FFC0',
    800: '#04FFB7',
    900: '#00E3A2',
  },
};

export default extendTheme({
  config,
  styles: {
    global: ({ theme }) => ({
      light: {
        color: theme.colors.gray[900],
        bg: theme.colors.backgroundColor,
        borderColor: theme.colors.gray[200],
        placeholderColor: theme.colors.gray[400],
      },
      dark: {
        color: theme.colors.whiteAlpha[900],
        bg: theme.colors.gray[800],
        borderColor: theme.colors.whiteAlpha[300],
        placeholderColor: theme.colors.whiteAlpha[400],
      },
      /*
        This will hide the focus indicator if the element receives focus via the mouse,
        but it will still show up on keyboard focus.
      */
      '[data-js-focus-visible] :focus:not([data-focus-visible-added])': {
        outline: 'none !important',
        boxShadow: 'none !important',
      },
    }),
  },
  colors,
  fonts: {
    body:
      'Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    heading:
      'Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    mono: 'Menlo, monospace',
  },
  radii: {
    none: '0',
    sm: '0.125rem',
    md: '0.25rem',
    lg: '0.5rem',
    xl: '2rem',
    full: '9999px',
  },
});
