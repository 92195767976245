module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BRING YOUR BAR HOME","description":"Find cocktail delivery & takeaway near you","display":"minimal-ui","theme_color":"#6B46C1","background_color":"#FFFFFF","icon":"src/assets/Logo.png","lang":"en-GB","start_url":"/","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7a0ca1f7067ccb98343904dd234f9b20"},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"bringyourbarhome.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
