/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import 'focus-visible/dist/focus-visible';

import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';

import theme from './src/gatsby-plugin-chakra-ui/theme';

export const wrapRootElement = ({ element }) => (
  <ChakraProvider theme={theme}>{element}</ChakraProvider>
);
